import React from 'react';
import { CogIcon, UserIcon } from '@heroicons/react/24/solid';
import './index.css';

const RSideBar = () => {
  // console.log('RSideBar rendering');
  return (
    <div className="RSideBar">
      <h3>设置</h3>
      <ul>
        {[...Array(28)].map((_, index) => (
          <li key={index}>
            {index % 2 === 0 ? <CogIcon className="icon" /> : <UserIcon className="icon" />}
            {index % 2 === 0 ? '通用设置' : '用户信息'}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RSideBar;