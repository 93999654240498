import React from 'react';
import { PaperClipIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import './ToolBar.css';

const ToolBar = ({ onSend, inputMessage }) => {
    const handleSend = () => {
        if (inputMessage.trim()) {
            onSend(inputMessage);
        }
    };

  return (
    <div className="ToolBar">
      <div className="ToolBarLeft">
        <button><PaperClipIcon className="icon" /></button>
        {/* 可以添加更多工具按钮 */}
      </div>
      <div className="ToolBarRight">
        <button className="send-button" onClick={handleSend}><PaperAirplaneIcon className="icon" /></button>
      </div>
    </div>
  );
};

export default ToolBar;