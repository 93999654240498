import { makeAutoObservable } from 'mobx';

class UIStore {
  showLSideBar = window.innerWidth > window.innerHeight;
  showRSideBar = false;
  lSideBarWidth = 300;
  rSideBarWidth = 350; // 初始宽度
  footerHeight = 200; // 初始高度

  constructor() {
    makeAutoObservable(this);
  }

  toggleLSideBar = () => {
    this.showLSideBar = !this.showLSideBar;
  }

  toggleRSideBar = () => {
    this.showRSideBar = !this.showRSideBar;
    // console.log('toggleRSideBar called, new state:', this.showRSideBar);
  }

  setLSideBarWidth = (width) => {
    const minWidth = 250;
    const maxWidth = window.innerWidth * 0.5;
    this.lSideBarWidth = Math.max(minWidth, Math.min(width, maxWidth));
  }

  setRSideBarWidth = (width) => {
    const minWidth = 300;
    const maxWidth = window.innerWidth * 0.5;
    this.rSideBarWidth = Math.max(minWidth, Math.min(width, maxWidth));
  }

  handleResize = () => {
    const shouldShowSidebars = window.innerWidth > window.innerWidth * 0.5;
    this.showLSideBar = shouldShowSidebars;
    this.showRSideBar = shouldShowSidebars && this.showRSideBar;
    
    if (!shouldShowSidebars) {
      this.lSideBarWidth = 250; // 重置为默认宽度
      this.rSideBarWidth = 300; // 重置为默认宽度
    }
  }

  startResizing = () => {
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.stopResizing);
  }

  stopResizing = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.stopResizing);
  }

  handleMouseMove = (e) => {
    this.setLSideBarWidth(e.clientX);
  }

  startResizingRSideBar = () => {
    document.addEventListener('mousemove', this.handleMouseMoveRSideBar);
    document.addEventListener('mouseup', this.stopResizingRSideBar);
  }

  stopResizingRSideBar = () => {
    document.removeEventListener('mousemove', this.handleMouseMoveRSideBar);
    document.removeEventListener('mouseup', this.stopResizingRSideBar);
  }

  handleMouseMoveRSideBar = (e) => {
    const newWidth = window.innerWidth - e.clientX;
    this.setRSideBarWidth(newWidth);
  }

  setFooterHeight = (height) => {
    const minHeight = 200;
    const maxHeight = window.innerHeight * 0.5;
    this.footerHeight = Math.max(minHeight, Math.min(height, maxHeight));
    // console.log('Footer height set to:', this.footerHeight);
  }

  startResizingFooter = () => {
    document.addEventListener('mousemove', this.handleMouseMoveFooter);
    document.addEventListener('mouseup', this.stopResizingFooter);
  }

  stopResizingFooter = () => {
    document.removeEventListener('mousemove', this.handleMouseMoveFooter);
    document.removeEventListener('mouseup', this.stopResizingFooter);
  }

  handleMouseMoveFooter = (e) => {
    const newHeight = window.innerHeight - e.clientY;
    this.setFooterHeight(newHeight);
  }
}

export const uiStore = new UIStore();

window.addEventListener('resize', () => uiStore.handleResize());
uiStore.handleResize(); // 初始化时调用一次