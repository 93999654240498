import { observer } from 'mobx-react-lite';
import './App.css';
import LSideBar from './components/LSideBar';
import RSideBar from './components/RSideBar';
import Main from './components/Main';
import Header from './components/Header';
import Footer from './components/Footer';
import ReSizer from './components/ReSizer';
import ChatInterface from './components/ChatInterface';
import { stores } from './stores';

const App = observer(() => {
  const { uiStore } = stores;

  return (
    <div className="App">
      {uiStore.showLSideBar && (
        <div 
          className="left-panel" 
          style={{ width: uiStore.lSideBarWidth }}
        >
          <LSideBar />
          <ReSizer 
            onResize={uiStore.startResizing}
            direction="horizontal"
          />
        </div>
      )}
      <div className="right-panel">
        <Header />
        <div className="content">
          <Main />
          {uiStore.showRSideBar && (
            <div className="right-sidebar-container" style={{ width: uiStore.rSideBarWidth }}>
              <ReSizer 
                onResize={uiStore.startResizingRSideBar}
                direction="horizontal"
                position="left"
              />
              <RSideBar />
            </div>
          )}
        </div>
        <div className="footer-container" style={{ height: uiStore.footerHeight }}>
          <ReSizer 
            onResize={uiStore.startResizingFooter}
            direction="vertical"
            position="top"
          />
          <Footer>
            <ChatInterface />
          </Footer>
        </div>
      </div>
    </div>
  );
});

export default App;



