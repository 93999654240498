import React, { useState } from 'react';
import InputArea from './Footer/InputArea';
import ToolBar from './Footer/ToolBar';
import { sendMsgToOpenAI } from '../utils/useOpenAi';

const ChatInterface = () => {
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([
    { role: 'system', content: '欢迎使用智能助手！' }
  ]);

  const handleSend = async (message) => {
    if (message.trim()) {
      const userMessage = { role: 'user', content: message };
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);
      setInputMessage('');
      try {
        const response = await sendMsgToOpenAI(updatedMessages);
        const assistantMessage = { role: 'assistant', content: response };
        setMessages(prev => [...prev, assistantMessage]);
        console.log('OpenAI响应:', response);
      } catch (error) {
        console.error('调用OpenAI API时出错:', error);
        const errorMessage = { role: 'error', content: '抱歉，发生了错误。' };
        setMessages(prev => [...prev, errorMessage]);
      }
    }
  };

  return (
    <div className="ChatInterface">
      {messages.map((msg, index) => (
        <div key={index} className={`message ${msg.role}`}>
          {msg.content}
        </div>
      ))}
      <InputArea onSend={handleSend} inputMessage={inputMessage} setInputMessage={setInputMessage} />
      <ToolBar onSend={handleSend} inputMessage={inputMessage} />
    </div>
  );
};

export default ChatInterface;