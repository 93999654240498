import OpenAI from "openai";

// 配置 OpenAI API，设置 API 密钥
const openai = new OpenAI({
//   apiKey: "sk-HQTEROWGqB1L81tfa0fI6J0b1riW1B0oLENyLsPradb4SzBB",
//   baseURL: "https://api.chatanywhere.tech/v1",
  apiKey: "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",
  baseURL: "https://api.groq.com/openai/v1",
  dangerouslyAllowBrowser: true
});

// 导出异步函数，用于发送消息到 OpenAI 并返回响应
export async function sendMsgToOpenAI(messages) {
    try {
        // 使用 OpenAI API 创建聊天补全
        const response = await openai.chat.completions.create({
            // model: "gpt-4o-mini",
            // model: "llama3-70b-8192",
            model: "llama-3.1-70b-versatile",
            messages: messages,
            temperature: 1,
            max_tokens: 4096,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
            response_format: { "type": "text" }
        });
        
        // 返回生成的文本
        return response.choices[0].message.content;
    } catch (error) {
        console.error('OpenAI API 调用错误:', error);
        throw error;
    }
}

