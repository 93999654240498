import React from 'react';
import { observer } from 'mobx-react-lite';
import './index.css';
import { stores } from '../../stores';

const Main = observer(() => {
  const { messageStore } = stores;

  return (
    <div className="Main">
      {messageStore.messages.map((message, index) => (
        <div key={index} className={`chat-message ${message.role}`}>
          <pre>{message.content}</pre>
        </div>
      ))}
    </div>
  );
});

export default Main;