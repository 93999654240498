import React from 'react';
import { observer } from 'mobx-react-lite';
import { Bars3Icon, CogIcon } from '@heroicons/react/24/solid';
import './index.css';
import { stores } from '../../stores';

const Header = observer(() => {
  const { uiStore } = stores;

  const handleToggleRSideBar = () => {
    // console.log('Toggle button clicked');
    uiStore.toggleRSideBar();
  };

  return (
    <div className="Header">
      <div className="HeaderLeft">
        <button onClick={uiStore.toggleLSideBar}><Bars3Icon className="icon" /></button>
      </div>
      <div className="HeaderCenter">
        <span>AI 助手聊天</span>
      </div>
      <div className="HeaderRight">
        <button onClick={handleToggleRSideBar}><CogIcon className="icon" /></button>
      </div>
    </div>
  );
});

export default Header;