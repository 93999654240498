import React from 'react';
import './index.css';

const ReSizer = ({ onResize, direction = 'horizontal', position = 'right' }) => {
  const handleMouseDown = (e) => {
    e.preventDefault();
    onResize();
  };

  return (
    <div 
      className={`resizer ${direction} ${position}`} 
      onMouseDown={handleMouseDown}
    ></div>
  );
};

export default ReSizer;