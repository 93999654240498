import React from 'react';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/solid';
import './index.css';

function LSideBar() {
  return (
    <div className="LSideBar">
      <h3 className="sidebar-title">对话历史</h3>
      <ul className="conversation-list">
        {[...Array(27)].map((_, index) => (
          <li key={index}><ChatBubbleLeftIcon className="icon" /> 对话 {(index % 3) + 1}</li>
        ))}
      </ul>
    </div>
  );
}

export default LSideBar;