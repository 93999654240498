import { makeAutoObservable } from 'mobx';

class MessageStore {
  messages = [
    { role: 'system', content: '欢迎使用智能助手！' },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  addMessage = (message) => {
    this.messages.push(message);
  }
}

export const messageStore = new MessageStore();