import React from 'react';
import './InputArea.css';

const InputArea = ({ onSend, inputMessage, setInputMessage }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend(inputMessage);
    }
  };

  return (
    <div className="InputArea">
      <textarea 
        placeholder="在这里输入您的问题..." 
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        onKeyDown={handleKeyDown}
      ></textarea>
    </div>
  );
};

export default InputArea;