import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import './index.css';
import { stores } from '../../stores';
import ToolBar from './ToolBar';
import InputArea from './InputArea';
import { sendMsgToOpenAI } from '../../utils/useOpenAi';

const Footer = observer(() => {
  const { messageStore } = stores;
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([
    { role: 'system', content: '欢迎使用智能助手！' }
  ]);

  const handleSend = async (message) => {
    if (message.trim()) {
      const userMessage = { role: 'user', content: message };
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);
      setInputMessage(''); // 清空输入
      messageStore.addMessage(userMessage);
      try {
        const response = await sendMsgToOpenAI(updatedMessages);
        const assistantMessage = { role: 'assistant', content: response };
        setMessages(prev => [...prev, assistantMessage]);
        messageStore.addMessage(assistantMessage);
        console.log('OpenAI响应:', response);
      } catch (error) {
        console.error('调用OpenAI API时出错:', error);
        const errorMessage = { role: 'error', content: '抱歉，发生了错误。' };
        setMessages(prev => [...prev, errorMessage]);
        messageStore.addMessage(errorMessage);
      }
    }
  };

  return (
    <div className="Footer" style={{ height: '100%' }}>
      <ToolBar onSend={() => handleSend(inputMessage)} inputMessage={inputMessage} />
      <InputArea 
        onSend={handleSend} 
        inputMessage={inputMessage} 
        setInputMessage={setInputMessage} 
      />
    </div>
  );
});

export default Footer;